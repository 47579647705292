var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Dialog",
    {
      staticClass: "add-timeslot",
      attrs: { name: _vm.name, width: "475px" },
      on: { close: _vm.beforeClose },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function () {
            return [
              _c(
                "div",
                { staticStyle: { "font-size": "16px", "font-weight": "400" } },
                [_vm._v("Добавление авто")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        { staticClass: "w-100p" },
        [
          _c("IqTitle", { attrs: { "class-name": "mobileHeader" } }, [
            _vm._v("Тайм слот: в порядке очереди"),
          ]),
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "formAddTimeslot",
          staticClass: "form-add-timeslot-hand",
          attrs: { model: _vm.formAddTimeslot, rules: _vm.rules },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "df m-b-5",
              staticStyle: { "flex-direction": "column" },
            },
            [
              _c("div", { staticClass: "m-b-5" }, [_vm._v("Номер авто")]),
              _c("IqInputSearchForm", {
                attrs: {
                  "input-width": "100%",
                  prop: "plate_truck",
                  rules: _vm.rules.plate_truck,
                  disabled: _vm.isLoadingPostTimeslots,
                  loading: _vm.isLoadingPostTimeslots,
                },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.handleCloseDialog.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.formAddTimeslot.plate_truck,
                  callback: function ($$v) {
                    _vm.$set(_vm.formAddTimeslot, "plate_truck", $$v)
                  },
                  expression: "formAddTimeslot.plate_truck",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "df m-b-5",
              staticStyle: { "flex-direction": "column" },
            },
            [
              _c("div", { staticClass: "m-b-5" }, [_vm._v("Тип авто")]),
              _c("IqSelectForm", {
                attrs: {
                  prop: "type",
                  rules: _vm.rules.type,
                  options: _vm.autoTypeRequired,
                  "key-label": "title",
                  "key-value": "val",
                  "input-width": "100%",
                  "is-super-important-label-width": true,
                },
                model: {
                  value: _vm.formAddTimeslot.type,
                  callback: function ($$v) {
                    _vm.$set(_vm.formAddTimeslot, "type", $$v)
                  },
                  expression: "formAddTimeslot.type",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "df m-b-5",
              staticStyle: { "flex-direction": "column" },
            },
            [
              _c("div", { staticClass: "m-b-5" }, [_vm._v("Культура")]),
              _c("IqSelectForm", {
                attrs: {
                  prop: "culture_id",
                  rules: _vm.rules.culture_id,
                  options: _vm.cultureList,
                  "key-label": "name",
                  "key-value": "id",
                  "input-width": "100%",
                  "is-super-important-label-width": true,
                },
                model: {
                  value: _vm.formAddTimeslot.culture_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.formAddTimeslot, "culture_id", $$v)
                  },
                  expression: "formAddTimeslot.culture_id",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "df m-b-5",
              staticStyle: { "flex-direction": "column" },
            },
            [
              _c("div", { staticClass: "m-b-5" }, [_vm._v("Экспортер")]),
              _c("IqSelectForm", {
                attrs: {
                  prop: "exporter_id",
                  rules: _vm.rules.exporter_id,
                  options: _vm.exportersList,
                  "key-label": "name",
                  "key-value": "id",
                  "input-width": "100%",
                  disabled: _vm.isDisabledExporters,
                  "is-super-important-label-width": true,
                },
                model: {
                  value: _vm.formAddTimeslot.exporter_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.formAddTimeslot, "exporter_id", $$v)
                  },
                  expression: "formAddTimeslot.exporter_id",
                },
              }),
            ],
            1
          ),
          _vm.isTerminalRequireInn
            ? _c(
                "div",
                { staticClass: "df jc-fs ai-e m-b-20 common_supplier_item" },
                [
                  _c("IqInputSearchForm", {
                    attrs: {
                      prop: "inn",
                      rules: _vm.rules.innRule,
                      label: "ИНН поставщика",
                      "label-width": "175px",
                      "input-width": "265px",
                      disabled: _vm.isLoadingPostTimeslots,
                      loading: _vm.isLoadingPostTimeslots,
                      message: _vm.formAddTimeslot.common_supplier_id
                        ? _vm.formAddTimeslot.innName
                        : "",
                    },
                    on: {
                      search: function ($event) {
                        return _vm.fetchCommonSuppliersCheckInn(
                          _vm.formAddTimeslot.inn
                        )
                      },
                    },
                    model: {
                      value: _vm.formAddTimeslot.inn,
                      callback: function ($$v) {
                        _vm.$set(_vm.formAddTimeslot, "inn", $$v)
                      },
                      expression: "formAddTimeslot.inn",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "df m-b-5",
              staticStyle: { "flex-direction": "column" },
            },
            [
              _c("div", { staticClass: "m-b-5" }, [_vm._v("Статус")]),
              _c("IqInputForm", {
                attrs: { "input-width": "100%", disabled: "" },
                model: {
                  value: _vm.formAddTimeslot.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.formAddTimeslot, "status", $$v)
                  },
                  expression: "formAddTimeslot.status",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "df m-b-12",
              staticStyle: { "flex-direction": "column" },
            },
            [
              _c("div", { staticClass: "m-b-5" }, [_vm._v("Телефон водителя")]),
              _c("IqInputForm", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: "+7 ### ###-##-##",
                    expression: "'+7 ### ###-##-##'",
                  },
                ],
                attrs: {
                  prop: "phone",
                  "input-width": "100%",
                  rules: _vm.rules.phone,
                },
                model: {
                  value: _vm.formAddTimeslot.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.formAddTimeslot, "phone", $$v)
                  },
                  expression: "formAddTimeslot.phone",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "df m-b-5",
              staticStyle: { "flex-direction": "column" },
            },
            [
              _c("div", { staticClass: "m-b-5" }, [_vm._v("Проезд")]),
              _c("IqSelectForm", {
                attrs: {
                  prop: "detach",
                  rules: _vm.rules.detach,
                  options: [
                    { id: 0, name: "Разрешен" },
                    { id: 1, name: "Отказан" },
                  ],
                  "key-label": "name",
                  "key-value": "id",
                  "input-width": "100%",
                  "is-super-important-label-width": true,
                },
                model: {
                  value: _vm.formAddTimeslot.detach,
                  callback: function ($$v) {
                    _vm.$set(_vm.formAddTimeslot, "detach", $$v)
                  },
                  expression: "formAddTimeslot.detach",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-add-timeslot-hand__timeslot-wrap-btn" },
            [
              _c(
                "iq-button",
                {
                  staticClass: "w-100p",
                  attrs: { loading: _vm.isLoadingPostTimeslots },
                  on: { onClick: _vm.handleAddNewTimeslot },
                },
                [_vm._v(" Добавить тайм слот ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }